'use client';

import React from 'react';
import styles from './styles.module.css';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import { PillType } from './model';
import classNames from 'classnames';
import { $selectedRadioStore, toggleRadio, $selectedCheckboxStore, toggleCheckbox, THorizontalPills } from './model';
import { Pill } from '../Pill';

export type TProps = {
    config: THorizontalPills;
    index: number;
};

const HorizontalPills = ({ config, index }: TProps) => {
    const selectedRadio = useUnit($selectedRadioStore);
    const selectedCheckboxes = useUnit($selectedCheckboxStore);
    const arenaData = useUnit($arenaDataStore);
    const selectCheckbox = useUnit(toggleCheckbox);
    const selectRadio = useUnit(toggleRadio);
    const topElement = index === 0 ? 'top-element' : '';

    const handleRadioChange = (label: string) => {
        selectRadio({ group: config.horizontalGroup, label });
    };

    const handleCheckboxChange = (label: string) => {
        selectCheckbox({ group: config.horizontalGroup, label });
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>, pill: any) => {
        switch (config.pillType) {
            case PillType.Anchor:
                return;
            case PillType.Checkbox:
                handleCheckboxChange(pill.label);
                break;
            case PillType.Radio:
                handleRadioChange(pill.label);
                break;
            default:
                // TODO: Behavior for buttons
                break;
        }
    };

    const getSelectedState = (pill: any) => {
        if (config.pillType === PillType.Checkbox) {
            return selectedCheckboxes[config.horizontalGroup]?.has(pill.label);
        }

        if (config.pillType === PillType.Radio) {
            return selectedRadio[config.horizontalGroup] === pill.label;
        }
    };

    return (
        <div
            className={classNames(styles.horizontalPill, 'horizontalPills', topElement, {
                [styles.horizontalLinkNoTitle]: !config.title,
            })}
        >
            {config.title && <p className={`text-20 ark-ui-title ${styles.title}`}>{config.title}</p>}
            {config.pills.map((pill, index) => {
                return (
                    <div key={index} className="ark-ui-pill-label">
                        <Pill
                            label={pill.label || ''}
                            pillType={config.pillType}
                            prefix={config.horizontalGroup}
                            href={pill.href || '#'}
                            target={pill.target || '_self'}
                            leftImg={pill.leftImg}
                            rightImg={pill.rightImg}
                            override_border_color={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.border_color || ''
                            }
                            override_border_color_hover={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.border_color_hover || ''
                            }
                            override_border_color_focus={''}
                            override_border_color_active={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.border_color_active || ''
                            }
                            override_label_color={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.label_color || ''
                            }
                            override_label_color_hover={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.label_color_hover || ''
                            }
                            override_label_color_focus={''}
                            override_label_color_active={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.label_color_active || ''
                            }
                            override_background_color={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.background_color || ''
                            }
                            override_background_color_hover={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.background_color_hover || ''
                            }
                            override_background_color_focus={''}
                            override_background_color_active={
                                arenaData?.layout?.styleOverride?.horizontalGenericLinks?.background_color_active || ''
                            }
                            onClick={(e: any) =>
                                handleClick(e as React.MouseEvent<HTMLButtonElement, MouseEvent>, pill)
                            }
                            onChange={() => handleRadioChange(pill.label)}
                            selected={getSelectedState(pill)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default HorizontalPills;
