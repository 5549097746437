import classNames from 'classnames';
import { generateCardId } from '../../uikit/helper_funcs/generateCardId';
import { TCalltoaction } from './model';
import { ThumbnailIcon } from '../ThumbnailIcon';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';
import { useEffect } from 'react';

export const ButtonCallToAction = (props: TCalltoaction) => {
    const text: string = props.text || '';
    const iconStr: string = props?.['icon-url'] || '';
    const iconPos: string = props?.['icon-position'] || '';
    const {
        override_button_normal_border,
        override_button_normal_background,
        override_button_normal_color,
        override_button_hover_border,
        override_button_hover_background,
        override_button_hover_color,
        override_button_active_color,
        override_button_active_border,
        override_button_active_background,
        override_button_focus_color,
        override_button_focus_border,
        override_button_focus_background,
        override_button_focus_outline,
    } = props || {};

    const id = `${generateCardId('calltoaction')}-${props?.addToId || ''}`;
    const isIconBefore: boolean = iconPos === 'before';
    const sizeModClass = props?.size ? `__${props.size}` : '__medium';
    const styleModClass = props?.styling ? `__${props.styling}` : '__filled';
    const horPaddingsModClass = props?.styling !== 'text' ? ' padding-24-left padding-24-right' : '';
    const passedClass = props?.addClass ? ` ${props?.addClass}` : '';
    const tabIndex = parseInt(
        String(
            props?.tabIndex === undefined
                ? ETabIndexesOrderingLevels.OTHER_AUTO
                : props.tabIndex || ETabIndexesOrderingLevels.OTHER_AUTO
        )
    );

    const getIcon = () => {
        if (!iconStr || (!iconStr.match(/^http(s)\:\/\//gi) && !iconStr.match(/^data\:/gi))) return '';
        return (
            <ThumbnailIcon
                src={iconStr}
                className={`${text}-button-icon`}
                alt={''}
                width={undefined}
                height={undefined}
                marginStyle={`margin-${isIconBefore ? 'right' : 'left'}: .5rem`}
                isThumbnail={true}
            />
        );
    };

    const addOverride = (cssVar: string, value: any) => (value ? `${cssVar}: ${value};` : '');

    const generateStyling = (): string => {
        return `
                #${id} .button-content {
                    ${addOverride('--button-cta-text-color', override_button_normal_color)}
                    ${addOverride('--button-cta-bcg-color', override_button_normal_background)}
                    ${addOverride('--button-cta-border-color', override_button_normal_border)}
                }
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:hover #${id} .button-content,
                #${id} .button-content:hover {
                    ${addOverride('--button-cta-text-color', override_button_hover_color)}
                    ${addOverride('--button-cta-bcg-color', override_button_hover_background)}
                    ${addOverride('--button-cta-border-color', override_button_hover_border)}
                }
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:active #${id} .button-content,
                #${id} .button-content:active {
                    ${addOverride('--button-cta-text-color', override_button_active_color)}
                    ${addOverride('--button-cta-border-color', override_button_active_border)}
                    ${addOverride('--button-cta-bcg-color', override_button_active_background)}
                }
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:focus #${id} .button-content,
                [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:focus-visible #${id} .button-content,
                #${id} .button-content:focus,
                #${id} .button-content:focus-visible {
                    ${addOverride('--button-cta-text-color', override_button_focus_border)}
                    ${addOverride('--button-cta-bcg-color', override_button_focus_background)}
                    ${addOverride('--button-cta-border-color', override_button_focus_border)}
                    ${addOverride('--button-cta-outline-color', override_button_focus_border)}
                }
            `;
    };

    useEffect(() => {
        const styleTag = document.createElement('style');
        styleTag.innerHTML = generateStyling();
        document.head.appendChild(styleTag);
        // Cleanup the style tag on unmount to prevent memory leaks
        return () => {
            document.head.removeChild(styleTag);
        };
    }, [id]);

    return (
        <>
            <button
                id={id}
                role="button"
                tabIndex={tabIndex}
                aria-label={!text ? 'Clickable button' : ''}
                className={classNames('ark-ui-atom-button-calltoaction-button', styleModClass)}
            >
                <p
                    className={classNames(
                        sizeModClass,
                        horPaddingsModClass,
                        passedClass,
                        'button-content',
                        'text-18',
                        'padding-8-top',
                        'padding-8-bottom'
                    )}
                >
                    {isIconBefore ? getIcon() : ''}
                    {!text ? <slot></slot> : text}
                    {!isIconBefore ? getIcon() : ''}
                </p>
            </button>
        </>
    );
};
