import React from 'react';

type TUiKitRenderStaticProps = {
    style?: React.CSSProperties;
    tagName: string | undefined;
    innerHTML: string | undefined | null;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void; // Define onClick prop
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const UiKitRenderStatic = (props: TUiKitRenderStaticProps) => {
    const style = props.style;
    const onClick = props.onClick;
    const onChange = props.onChange;
    const html = props.innerHTML?.replaceAll?.(/(\s{2,})/g, ' ')?.replaceAll?.(/\n/g, '');
    const targetTag = 'div';
    const targetProps = {
        ...(onClick ? { onClick } : {}),
        ...(onChange ? { onChange } : {}),
        ...(style ? { style } : {}),
        ...{ className: props.tagName },
        ...{ ['ark-ui']: '' }, // adding ['ark-ui'] to div to have basic styles same as for webcomponents that are setting it by default
        dangerouslySetInnerHTML: { __html: html }, // inner html added in both cases webcomponent and div to remove CLS and unblock initial render
        suppressHydrationWarning: true,
    };

    const CustomComponent = () => <>{React.createElement(targetTag, targetProps)}</>;
    return <CustomComponent />;
};
